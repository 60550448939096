.grid {
  display: grid;
  margin-top: -16px;
  color: #333;
  grid-gap: 24px;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto auto 1fr;
  > div {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
    padding: 12px;
    border-radius: 4px;
  }
}
.title {
  text-transform: uppercase;
  font-weight: 900;
  font-size: 15px;
  line-height: 20px;
}
.status {
  grid-row: 1;
  margin-top: 16px;
  margin-bottom: -12px;
  grid-column-start: 1;
  grid-column-end: 4;
  box-shadow: none !important;
}
.progress {
  >[class~="ant-progress-inner"] {
    width: 60px !important;
    height: 60px !important;
    font-size: 13px !important;
  }
}
@keyframes spinIcon {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.progressLabels {
  font-weight: bold;
  font-size: 14px;
  min-width: 120px;
}
.step {
  margin-top: 8px;
  padding: 14px 6px !important;
  .undo {
    position: absolute;
    top: -24px;
    color: #C2CFE0;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: bold;
  }
  &[class~="ant-steps-item-wait"] {
    .icon svg {
      animation: spinIcon 2s infinite;
      animation-timing-function: linear;
    }
  }
  &.active {
    &[class~="ant-steps-item-error"] {
      .stepContent {
        [class~="anticon"] {
          background-color: #FB4D4F;
        }
        h4 {
          color: #FB4D4F;
        }
      }
    }
    .stepContent {
      [class~="anticon"] {
        background-color: #109CF1;
      }
      h4 {
        font-weight: bold;
        color: #109CF1;
      }
    }
    [class~="ant-steps-item-title"]:after {
      position: absolute;
      top: 19px;
      left: 100%;
      display: block;
      width: 9999px;
      height: 4px;
      background: #B9E3FF;
      content: '';
      @media (max-width: 768px) {
        width: 180px;
      }
    }
  }
  &.notPayed {
    .stepContent {
      [class~="anticon"] {
        background-color: #ff4e50;
      }
      h4 {
        font-weight: bold;
        color: #ff4e50;
      }
    }
  }
  &.future {
    .stepContent {
      [class~="anticon"] {
        background-color: #B9E3FF;
      }
      h4 {
        font-weight: bold;
        color: #B9E3FF;
      }
    }
    [class~="ant-steps-item-title"]:after {
      position: absolute;
      top: 19px;
      left: 100%;
      display: block;
      width: 9999px;
      height: 4px;
      background: #B9E3FF;
      content: '';
      @media (max-width: 768px) {
        width: 180px;
      }
    }
  }
  [class~="ant-steps-item-icon"] {
    display: none;
  }
  .iconDates {
    background-color: #C2CFE0;
    color: #fff;
    height: 40px;
    font-size: 12px;
    font-weight: bold;
    border-radius: 20px;
    line-height: 12px;
    padding: 8px;
    text-align: center;
    > span {
      font-weight: normal;
    }
  }
  [class~="ant-steps-item-title"] {
    padding-right: 6px;
    &:after {
      position: absolute;
      top: 19px;
      left: 100%;
      display: block;
      width: 9999px;
      height: 4px;
      background: #C2CFE0;
      content: '';
      @media (max-width: 768px) {
        width: 180px;
      }
    }
  }
  .stepContent {
    // cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    [class~="anticon"] {
      border-radius: 50%;
      background-color: #6FD89C;
      width: 40px;
      height: 40px;
      border: 2px solid #ffffff;
      box-shadow: 0 3px 8px rgba(0,0,0,.2);
      display: flex;
      align-items: center;
      justify-content: center;
    }
    h4 {
      text-align: center;
      font-size: 12px;
      color: #6FD89C;
      text-transform: uppercase;
      margin: 8px 0 0;
      line-height: 12px;
    }
    svg {
      color: #FFFFFF;
    }
  }
}
.progressWrapper {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
}
.vessels {
  grid-row: 2;
  grid-column: 1;
  display: flex;
  flex-flow: column;
  position: relative;
}
.customer {
  grid-row: 2;
  grid-column: 2;
  display: flex;
  flex-flow: column;
  position: relative;
}
.content {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row: 3;
}
.schedule {
  grid-row: 2;
  grid-column: 3;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
}
.sider {
  grid-row-start: 2;
  grid-row-end: 4;
  grid-column: 3;
  &.siderWithSchedule {
    grid-row-start: 3;
  }
  > ul {
    list-style: none;
    margin: 0;
    padding: 0;
    > li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
    }
  }
}
.link {
  color: #109CF1;
  text-decoration: underline;
  font-weight: bold;
  &:hover {
    cursor: pointer;
    text-decoration: none;
  }
}
.info {
  color: #109CF1;
  font-weight: bold;
}
.divider {
  margin: 24px -12px;
  width: calc(100% + 24px);
}
.ant-tooltip-inner {
  color: yellow;
  background-color: green;
}