@import "~antd/dist/antd.less";
@import "~react-big-calendar/lib/css/react-big-calendar.css";
@import "src/theme/main";
@import "src/layouts/calendar/calendar";

body {
   font-family: "Nunito Sans", sans-serif;
   font-size: 16px;
}
a {
   color: #0496ff;
}
.content-container {
   background: #fff;
   border-radius: 4px;
   box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
}
.content-container.padded {
   padding: 16px;
}
.content-container .ant-tabs-nav {
   padding: 0 32px;
   font-size: 15px;
}
.content-container .ant-tabs-content-holder {
   padding: 0 16px 16px;

   @media (max-width: 1275px) and (min-width: 1200px) {
      padding: 0px 16px 0 0;
   }
   @media (max-width: 768px) {
      padding: 0px 12px 12px;
   }
}
.custom-icon {
   max-width: 65%;
}
.ant-form .ant-form-item > .ant-form-item-label {
   font-size: 13px;
   font-weight: bold;
   padding: 8px 0 0;
}
.ant-modal-header {
   border-bottom: 0;
}
.ant-modal-header .ant-modal-title {
   font-size: 15px;
   line-height: 20px;
   font-weight: 900;
   text-transform: uppercase;
}

/* Gallery fullscreen */

div.fullscreen-group {
   position: relative;
   z-index: 16;
}
div.fullscreen-group::before {
   content: "";
   position: fixed;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   z-index: 1;
   pointer-events: none;
   touch-action: none;
   opacity: 0;
   transition: inherit;
   background: rgba(255, 255, 255, 0.9);
}

div.fullscreen-group.fullscreen-group--expanded::before {
   opacity: 1;
   z-index: 1;
   pointer-events: initial;
   touch-action: initial;
   background: rgba(255, 255, 255, 0.9);
}
div.fullscreen-group.fullscreen-group--expanded button.fullscreen-toggle {
   opacity: 1;
}
div.fullscreen-group.fullscreen-group--expanded button.fullscreen-btn {
   cursor: zoom-out;
}

div.fullscreen-container {
   position: absolute;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
}

button.fullscreen-toggle {
   position: fixed;
   top: 0;
   bottom: 0;
   padding: 20px;
   border: none;
   background: none;
   opacity: 0;
   transition: inherit;
   cursor: pointer;
   z-index: 9;
   transition: background 250ms ease;
}
button.fullscreen-toggle:active {
   background: rgba(0, 0, 0, 0.1);
}

button.fullscreen-toggle.toggle--left {
   left: 0;
}
button.fullscreen-toggle.toggle--right {
   right: 0;
}

button.fullscreen-btn {
   border: none;
   background: none;
   position: inherit;
   top: inherit;
   left: inherit;
   right: inherit;
   bottom: inherit;
   height: 100%;
   width: 100%;
   padding: 0;
   -webkit-appearance: none;
   cursor: zoom-in;
   outline: none;
}

button.fullscreen-exit-btn {
   border: none;
   background: none;
   position: fixed;
   top: 0;
   right: 0;
   padding: 20px;
   -webkit-appearance: none;
   cursor: pointer;
   z-index: 10;
}

div.fullscreen-image {
   position: inherit;
   top: inherit;
   left: inherit;
   right: inherit;
   bottom: inherit;
}

.ant-picker-calendar-mode-switch {
   display: none !important;
}

.ant-table-wrapper.contained {
   .ant-table-content > table {
      border-collapse: separate;
      border-spacing: 0 12px;
      display: flex;
      flex-direction: column;
   }
   .ant-table-thead,
   .ant-table-tbody {
      display: flex;
      flex-direction: column;
   }
   .ant-table-thead > tr {
      border-spacing: 0;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      & > th {
         background: transparent;
         border-bottom: none;
         top: 8px;
         font-weight: bold;
         width: 100%;
      }
   }
   .ant-table-tbody > tr {
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
      border-radius: 4px;
      background: #ffffff;

      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: stretch;
      margin-bottom: 10px;
      row-gap: 0px;
      &:hover {
         //background-color: #fafafa !important;
      }

      > td {
         border-bottom: none;
         width: 100%;
      }
   }
   .ant-table-summary > tr {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: stretch;
      margin-bottom: 10px;
      row-gap: 0px;
      > td {
         width: 100%;
      }
   }
   .ant-table-content .ant-table-tbody .ant-table-placeholder {
      justify-content: center;
      .ant-table-cell {
         .ant-empty.ant-empty-normal {
            width: 100px;
         }
      }
   }
}
.text-messaging-table {
   tr > td {
      margin: auto;
   }
}
.customers-table,
.vessel-table {
   td:first-child,
   th:first-child {
      min-width: 32px !important;
      max-width: 32px !important;
   }

   td:last-child,
   th:last-child {
      min-width: 82px !important;
      max-width: 82px !important;
   }
}
.vessel-table {
   td:nth-child(5),
   th:nth-child(5) {
      min-width: 90px !important;
      max-width: 90px !important;
   }
   td:nth-child(8),
   th:nth-child(8) {
      min-width: 142px !important;
   }
}
.status-table {
   td:first-child,
   th:first-child {
      min-width: 130px !important;
      max-width: 130px !important;
   }

   td:last-child,
   th:last-child {
      min-width: 100px !important;
      max-width: 100px !important;
   }
}
.timeclocks-table {
   td:first-child,
   th:first-child {
      min-width: 130px !important;
      max-width: 130px !important;
   }
   td:last-child,
   th:last-child {
      min-width: 115px !important;
      max-width: 115px !important;
   }
   .ant-table-thead > tr > th {
      top: 0 !important;
      padding: 16px 16px 8px;
   }
}
.work-table {
   td:last-child,
   th:last-child {
      min-width: 60px !important;
      max-width: 60px !important;
   }
   td:first-child,
   th:first-child {
      min-width: 140px !important;
      max-width: 140px !important;
   }
}
.companies-table {
   margin-top: 24px;
   .ant-table-thead {
      display: none !important;
   }
   .action {
      display: flex;
      justify-content: flex-end;
      align-items: center;
   }
   .ant-empty {
      margin: auto;
   }
}
.ant-popover {
   z-index: 1060 !important;
}
.ant-form-item {
   &.addon-after {
      .ant-form-item-control {
         .ant-form-item-control-input {
            min-height: 30px;
         }
      }
   }
}
.spin-full-height {
   height: 100%;
   > .ant-spin-container {
      height: 100%;
   }
}

//Refactor default antd styles

.ant-modal-content {
   border-radius: 4px !important;
}

.ant-modal-header {
   border-radius: 4px 4px 0 0;
   padding: 16px;
}

.ant-modal-body {
   padding: 0 18px;
}

.ant-form-item {
   margin-bottom: 16px;
}

.ant-input,
.ant-input-affix-wrapper {
   border-radius: 4px;
}

.ant-modal-footer .ant-btn,
.ant-btn {
   border-radius: 4px;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
   border-radius: 4px;
}

.ant-picker {
   border-radius: 4px;
}

input[type="time"]::-webkit-calendar-picker-indicator {
   display: none;
}
.grow {
   flex-grow: 1;
}

.antd-table-modified {
   border: 1px solid rgba(196, 196, 196, 1);
   border-radius: 8px;
   padding: 4px;
   width: 100%;
   ::-webkit-scrollbar {
      width: 8px;
      height: 8px;
      background-color: #fff;
   }
   ::-webkit-scrollbar-thumb {
      background: rgba(196, 196, 196, 1);
      border-radius: 3px;
   }
}

.ant-table-ping-left:not(.ant-table-has-fix-left) .ant-table-container::before,
.ant-table-ping-right:not(.ant-table-has-fix-right) .ant-table-container::after {
   box-shadow: none;
}
.fullWidthLabel label {
   width: 100%;
}
.transparentPopoverWrapper {
   .ant-popover-inner-content {
      padding: 0;
      background: transparent;
   }
}

.ant-checkbox-wrapper + .ant-checkbox-wrapper {
   margin-left: 0;
}

.ant-btn {
   box-shadow: none;
}

.ant-steps {
   @media (max-width: 768px) {
      gap: 54px;
      overflow: auto;
   }
}

.ant-steps-item {
   @media (max-width: 768px) {
      overflow: visible;
   }
}

.ant-upload.ant-upload-select-picture-card {
   margin-right: 0;
   width: 100%;
   height: 100%;
}

.ant-picker-time-panel-column::after {
   height: 0px;
}
.ant-switch {
   min-width: 40px;
   background-color: #dcdfe3;
   &.ant-switch-checked {
      background-color: #109cf1;
   }
}

.ant-input-textarea-show-count::after {
   color: #494949;
   font-weight: 700;
   margin-bottom: 0px !important;
}

.ant-form-item-has-error .ant-input-textarea-show-count::after {
   color: #ff4d4f;
}

.ant-form-item-has-error .text_editor_wrapper {
   border: 1px solid red;
}
