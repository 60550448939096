.editor,
.editor__white {
   background: #fbfbff;
   border: 1px solid #ededed;
   box-sizing: border-box;
   border-radius: 4px;
   height: 270px;
   padding: 8px;
   overflow: auto;
   &::-webkit-scrollbar {
      width: 5px;
   }
   &::-webkit-scrollbar-track {
      background: #fbfbff;
      border-radius: 8px;
      border: 1px solid #ededed;
   }

   &::-webkit-scrollbar-thumb {
      background: #0496ff;
      border-radius: 8px;
   }

   &::-webkit-scrollbar-thumb:hover {
      background: #40a9ff;
   }
}
.editor__white {
   background: #fff;
   height: 200px;
   border: none;
   outline: none;
   &:focus {
      border: 1px solid #40a9ff;
   }
   p {
      margin: 0;
      word-break: initial;

      & .ProseMirror-trailingBreak {
         content: " ";
         display: block;
         height: 10px;

         &:before {
            display: block;
            margin-top: 2px;
            content: "";
         }
         &:after {
            content: ".";
            visibility: hidden;
            display: block;
         }
      }
   }
}
