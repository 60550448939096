body {
   &::-webkit-scrollbar {
      width: 10px;
   }

   /* Track */

   &::-webkit-scrollbar-track {
      background: #f1f1f1;
   }

   /* Handle */

   &::-webkit-scrollbar-thumb {
      background: #888;
   }

   /* Handle on hover */

   &::-webkit-scrollbar-thumb:hover {
      background: #555;
   }
}

.input-element {
   border-radius: 4px;
}

.modal-window {
   & .ant-modal-body {
      padding: 16px;
   }
}

.modal-window-without-main-buttons {
   & .ant-modal-body {
      padding: 16px;
   }

   & .ant-modal-footer {
      border-top: 0;
   }
}

.little-cart {
   background: #ffffff;
   box-shadow: 0 1px 7px rgba(0, 0, 0, 0.15);
   border-radius: 4px;
   margin: 4px;
}

.form-item {
   &.ant-form-item {
      margin-bottom: 0;
   }
}

.tag {
   &.ant-tag {
      height: 13px;
      font-size: 13px;
      line-height: 10px;
      color: #109cf1;
   }
}

.short-text {
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
}

.with-underline {
   border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.progress {
   padding: 0;

   .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
      stroke: #44cc7e;
      padding: 0;
   }

   .ant-progress-outer {
      padding: 0;
   }
}
