@border-color: #d9d9d9;
@border: 1px solid @border-color;
@event-color: #109cf1;
@tablet: 1024px;

.rbc-calendar {
   .rbc-off-range-bg {
      background: #ffffff;
   }
   .rbc-month-header {
      span {
         font-size: 16px;
      }
   }
   .rbc-month-row {
      border-top: @border;
      .rbc-row-content {
         display: flex;
         flex: 1 0;
         flex-direction: column;
         .rbc-row {
            &:first-child {
               order: 1;
               margin-top: auto;
            }
         }
      }
      .rbc-show-more {
         margin: 4px 8px 0 4px;
         padding: 6px 5px 4px 5px;
         height: 30px;
         border-radius: 5px;
         font-weight: 700;
         font-size: 13px;
         background: @event-color;
         color: #ffffff;
      }
      &:last-child {
         border-bottom: @border;
      }
      .rbc-day-bg.rbc-today {
         border: 1px solid #109cf1;
         background-color: #ffffff;
      }
      .rbc-event {
         margin-top: 8px;
      }
      .rbc-date-cell a {
         font-size: 18px;
      }
      .rbc-date-cell.rbc-now a {
         font-size: 15px;
         background: @event-color;
         color: #ffffff;
         border-radius: 50%;
         height: 26px;
         width: 26px;
         line-height: 26px;
         display: inline-block;
         text-align: center;
         margin-bottom: 3px;
      }
   }
   .rbc-month-view {
      border: none;
   }
   .rbc-time-view {
      border: none;
   }
   .rbc-header {
      border: none;
   }
   .rbc-time-header-content {
      border-left: none;
      display: flex;
      flex-direction: column;
   }
   .rbc-day-bg {
      border-left: @border;
      &:last-child {
         border-right: @border;
      }
   }
   .rbc-event,
   .rbc-day-slot .rbc-background-even {
      background-color: @event-color;
      border-radius: 4px;
      margin: 2px;
      border: none;
   }
   .rbc-allday-cell {
  flex: 1;
   }
   .rbc-current-time-indicator {
      display: none;
   }
   .rbc-today {
      background-color: #ffffff;
   }
   .rbc-time-header-cell-single-day {
      display: block;
   }
   .rbc-day-bg:last-child {
      border-right: none;
   }
   .rbc-month-row {
      border-right: 1px solid #d9d9d9;
   }
}
.rbc-event-label {
   display: none;
}

@media (max-width: @tablet) {
   .rbc-calendar {
      .rbc-time-view {
         .rbc-event {
            min-height: 50px;
         }
         .rbc-time-header {
            border-left: 1px solid #ddd;
            // border-top: 1px solid #DDD;
         }
      }
      .rbc-month-row {
         .rbc-row-content {
            flex-direction: column-reverse;
            .rbc-row-content-scroll-container {
               .rbc-row {
                  .rbc-event-content {
                     text-align: center;
                     font-size: 20px;
                  }
               }
            }
            .rbc-row {
               .rbc-date-cell {
                  padding: 0;
                  div {
                     text-align: center;
                  }
                  div {
                     a {
                        width: 100%;
                        color: #000000;
                     }
                  }
               }
               .rbc-now {
                  a {
                     font-size: 18px;
                     background: none;
                     line-height: 28px;
                  }
               }
            }
         }
      }
   }
}
